<template>
	<div>
		<div class="page_banner"><img src="@/assets/images/concate/concate.jpg">
			<div class="words">
				<p>
					<span>联系我们</span>
					为您提供贴心服务
				</p>
			</div>
		</div>

		<div class="contactUs layout">
			<div class="words">
				<div class="top">
					<h3>河北凰聚科技有限公司</h3>
					<p>
						联系电话：150-5719-5347 <br />
						电子邮箱：huangju@163.com <br />
						邮 编：050000 <br />
					</p>
					<p>联系地址：河北省石家庄市新华区中山西路77号恒大中心1303室</p>

				</div>
			</div>
			<div class="map" id="dituContent">
			</div>

		</div>

	</div>
</template>
<script>
export default ({
	data() {
		return {
			map: "",
		}
	},
	methods: {
		//创建地图函数：
		createMap() {
			// 百度地图API功能
			var map = new BMap.Map('dituContent');
			var poi = new BMap.Point(114.858452, 37.893211);
			map.centerAndZoom(poi, 15);
			map.enableScrollWheelZoom();

			var content = '<div style="margin:0;line-height:20px;padding:2px;">' +
				'地址：河北省石家庄市新华区中山西路77号恒大中心1303室<br/>电话：150-5719-5347<br/>简介：河北凰聚科技有限公司有限公司创立于2022年，是一家创新型集研发、生产、销售为一体的软件硬件高新技术企业，一直专注从未改变。' +
				'</div>';

			// 创建检索信息窗口对象
			var searchInfoWindow = new BMapLib.SearchInfoWindow(map, content, {
				title: "河北凰聚科技有限公司",// 标题
				width: 300, // 宽度
				height: 105, // 高度
				panel: "panel", // 检索结果面板
				enableAutoPan: true, // 自动平移
				searchTypes: [
					BMAPLIB_TAB_SEARCH, // 周边检索
					BMAPLIB_TAB_TO_HERE, // 到这里去
					BMAPLIB_TAB_FROM_HERE // 从这里出发
				]
			});
			var marker = new BMap.Marker(poi);
			marker.addEventListener("click", function (e) {
				searchInfoWindow.open(marker);
			});
			searchInfoWindow.open(marker);
			map.addOverlay(marker);
		},
	},
	mounted() {
		this.createMap();//创建地图
	}
});
</script>
<style lang="less" scoped>
/*联系我们*/
.contactUs {
	padding: 50px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.words {
		display: flex;
		align-items: center;
		width: 40%;
		background-image: linear-gradient(-180deg, #285ff9 64%, #5286ff 100%);

		.top {
			margin: 40px 11.5%;

			h3 {
				line-height: 52px;
				font-size: 24px;
				color: #fff;
			}

			p {
				margin-top: 15px;
				line-height: 24px;
				font-size: 16px;
				color: #fff;

				&:last-child {
					margin-top: 30px;
				}
			}

			a {
				display: inline-block;
				margin-right: 15px;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				width: 40px;
				height: 40px;
			}
		}
	}

	.map {
		width: 60%;
		height: 500px;
	}

	@media screen and(max-width:1200px) {
		margin: 0;
		padding: 0 0 20px;
		flex-direction: column-reverse;

		.words {
			width: 100%;
		}

		.map {

			width: 100%;
			height: 300px;
		}
	}
}
</style>
